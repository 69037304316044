body {
  background: linear-gradient(to right, #193f88 0%, #0e082c 99%);
}

.App {
  text-align: center;
}

.ant-menu-item-selected {
  border-bottom: solid 2px #25cc9c !important;
}

.ant-menu-item:hover,
.ant-menu-item-selected a {
  color: #25cc9c !important;
}

/*.ant-btn-primary {*/
/*  background: #25cc9c !important;*/
/*}*/
